<template>
  <v-card outlined>
    <v-toolbar dense dark color="primary darken-1" elevation="0">
      <v-toolbar-title v-text="'Severity'" />
    </v-toolbar>

    <v-card-text>
      <div v-if="assessment.assessment_type === 'dropped object'">
        <v-row no-gutters align="center">
          <v-col cols="4" class="text-center">Dent Depth [%]</v-col>
          <v-col cols="2" class="text-center">0 - 2</v-col>
          <v-col cols="2" class="text-center">2 - 4</v-col>
          <v-col cols="2" class="text-center">4 - 6</v-col>
          <v-col cols="2" class="text-center">> 6</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>
        <v-row no-gutters class="pb-5">
          <v-col cols="4" class="text-center">Severity</v-col>

          <v-col cols="2" class="text-center">
            <pibot-risk-chip v-if="severity === 1 || severity === 2" :risk="severity" :color="color" small :tooltip="true" tooltip-placement="bottom" />
            <span v-else v-text="'1 / 2'" />
          </v-col>

          <v-col cols="2" class="text-center">
            <pibot-risk-chip v-if="severity === 3" :risk="severity" :color="color" small :tooltip="true" tooltip-placement="bottom" />
            <span v-else v-text="'3'" />
          </v-col>

          <v-col cols="2" class="text-center">
            <pibot-risk-chip v-if="severity === 4" :risk="severity" :color="color" small :tooltip="true" tooltip-placement="bottom" />
            <span v-else v-text="'4'" />
          </v-col>

          <v-col cols="2" class="text-center">
            <pibot-risk-chip v-if="severity === 5" :risk="severity" :color="color" small :tooltip="true" tooltip-placement="bottom" />
            <span v-else v-text="'5'" />
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row>
          <v-col cols="12" class="pt-2">
            <strong v-text="severityText" />
          </v-col>
        </v-row>
      </div>

      <!-- <v-row align="center">
        <v-col cols="4" class="text-center">
          <span v-text="'Event(s)* within 50 m of location?'" />
        </v-col>

        <v-col cols="8">
          <v-row no-gutters class="text-center">
            <v-col cols="12" class="font-weight-bold" v-text="tableTitle" />
            <v-col cols="12">
              <v-row no-gutters>
                <v-col v-for="header in tableHeaders" :key="header" v-html="header"/>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-divider />
        </v-col>

        <v-col cols="4">
          <v-row no-gutters class="text-center font-weight-bold">
            <v-col cols="12" v-text="'Yes'" />
            <v-col cols="12" v-text="'No'" />
          </v-row>
        </v-col>

        <v-col cols="8">
          <v-row no-gutters class="text-center">
            <v-col cols="12" v-for="([key, items]) in Object.entries(matrix)" :key="key">
              <v-row no-gutters>
                <v-col v-for="(item, index) in items" :key="index">
                  <template v-if="key === 'yes'">
                    <pibot-risk-chip v-if="hasNearbyEvents && item === severity" :risk="item" :color="color" small :tooltip="true" tooltip-placement="bottom" />
                    <span v-else v-text="item" />
                  </template>

                  <template v-else-if="key === 'no'">
                    <pibot-risk-chip v-if="item === severity" :risk="item" :color="color" small :tooltip="true" tooltip-placement="bottom" />
                    <span v-else v-text="item" />
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->

      <!-- Notes -->
      <!-- <v-row>
        <v-col cols="12" v-text="'* Other incidents and pipeline anomalies such as corrosion features'" />
      </v-row> -->

      <!-- Repairs -->
      <v-row>
        <v-col cols="12" class="pt-2">
          <strong v-text="repairText" />
        </v-col>
      </v-row>

      <!-- Severity legend -->
      <v-row>
        <v-col cols="12" class="pt-2">
          <pibot-eprs-severity-legend />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'pibot-eprs-severity',
  props: {
    assessment: {
      type: Object,
      required: true
    }
  },
  components: {
    'pibot-risk-chip': () => import('@/components/chips/RiskChip'),
    'pibot-eprs-severity-legend': () => import('./SeverityLegend')
  },
  data: () => ({
    // matrix: {
    //   yes: [2, 3, 4, 5, 5],
    //   no: [1, 2, 3, 4, 5]
    // },
    severityText: 'Combination defects always require immediate attention and the severity therefore therefore cannot be lower than 4.'
  }),
  computed: {
    // tableTitle () {
    //   switch (this.assessment.assessment_type) {
    //     case 'dropped object':
    //       return 'Dent depth [%]'
    //     case 'dent':
    //       return 'Safe operating pressure over maximum operating pressure'
    //     case 'fatigue':
    //       return 'Lower bound cycles until failure'
    //     default:
    //       return ''
    //   }
    // },
    // tableHeaders () {
    //   switch (this.assessment.assessment_type) {
    //     case 'dropped object':
    //       return ['0 - 5', '5 - 10', '10 - 15', '15- 20', '> 20']
    //     case 'dent':
    //       return ['> 1.2', '1 - 1.2', '0.9 - 0.1', '0.8 - 0.9', '< 0.8']
    //     case 'fatigue':
    //       return ['> 10<sup>6</sup>', '10<sup>5</sup> - 10<sup>6</sup>', '10<sup>4</sup> - 10<sup>5</sup>', '10<sup>3</sup> - 10<sup>4</sup>', '< 10<sup>3</sup>']
    //     default:
    //       return ''
    //   }
    // },
    repairText () {
      if ([1, 2].includes(this.severity)) return ''
      else if (this.depth > 6) return "Given the water depth, a 'clamp' repair could be considered"
      else return "Given the water depth, a 'clamp' or 'sleeve' repair could be considered"
    },
    // hasNearbyEvents () {
    //   return !!this.assessment.data.find(({ key }) => key === 'nearby-ili-events').value
    // },
    severity () {
      return this.assessment.data.find(({ key }) => key === 'severity').value
    },
    depth () {
      return this.assessment.data.find(({ key }) => key === 'depth').value
    },
    color () {
      switch (this.severity) {
        case 0:
        case 1:
        case 2:
          return 'success darken-2'

        case 3:
          return 'warning'

        case 4:
          return 'warning darken-1'

        case 5:
          return 'error darken-1'

        default:
          return undefined
      }
    }
  }
}
</script>
